.link {
	border-bottom: 1px solid;
	border-bottom-color: transparent;
	transition: color 0.1s ease-in-out;
	cursor: pointer;
	text-transform: uppercase;
	color: inherit;
	position: relative;
	text-decoration: none;
	display: inline-block;

	&:hover {
		color: #fff;

		&::after {
			transform: scaleX(1);
		}
	}

	&::after {
		border-bottom: 1px solid #fff;
		bottom: 0px;
		content: '';
		display: block;
		left: 0;
		position: absolute;
		transform: scaleX(0);
		transform-origin: left center;
		transition: transform 0.3s ease-out 0.1s;
		width: 100%;
	}

	font-size: 0.8rem;

	@media screen and (min-width: 320px) {
		font-size: calc(0.8rem + 4 * (100vw - 320px) / 1180);
	}
	@media screen and (min-width: 1180px) {
		font-size: 11px;
	}
}
