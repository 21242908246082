.link {
	display: flex;
	height: 50px;
	width: 50px;
	font-size: 1em;
	font-weight: normal;
	&::after {
		display: none;
	}

}

.small {
	height: 30px;
	width: 30px;
}
