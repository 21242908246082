.container {
	flex-shrink: 0;
	padding: 20px 0px 35px 0px;
	z-index: 10;
}

.content {
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	@media (min-width: 767px) {
		align-items: flex-end;
		flex-direction: row;
	}
}

.left {
	display: flex;

	justify-content: center;
	align-items: flex-start;
	flex-direction: column;

	@media (min-width: 768px) {
		justify-content: space-between;
		flex-wrap: wrap;

		align-items: baseline;
	}
}

.center {
	margin-top: 10px;

	@media (min-width: 767px) {
		margin-top: 0;
		margin-left: 50px;
		justify-content: space-between;
		flex-wrap: wrap;

		align-items: baseline;
	}
}

.right {
	margin-top: 10px;
	flex-direction: row;
	display: flex;
	align-items: flex-end;
	width: 100%;

	@media (min-width: 767px) {
		margin-top: 0;
		margin-left: auto;
		width: initial;
		//justify-content: space-between;
		//flex-wrap: wrap;
		//flex-direction: row;
		//align-items: baseline;
	}
}


.list {
	list-style: none;
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: row;
}

.listCenter {
	flex-direction: column;
}

.listRight {
	margin-right: auto;
	@media (min-width: 767px) {
		margin-right: 50px;
	}
}

.locale {
	display: flex;
	align-items: center;
}

.meta {
	font-size: 0.8rem;
	margin-left: 20px;

	&:first-child {
		margin-left: 0;
	}

	@media screen and (min-width: 320px) {
		font-size: calc(0.8rem + 4 * (100vw - 320px) / 1180);
	}
	@media screen and (min-width: 1180px) {
		font-size: 11px;
	}
}

.contact {
	font-size: 0.8rem;

	@media screen and (min-width: 320px) {
		font-size: calc(0.8rem + 4 * (100vw - 320px) / 1180);
	}

	@media screen and (min-width: 1180px) {
		font-size: 11px;
	}
}

.social {
	margin-left: 10px;
	font-size: 16px;
	line-height: 1;
}

.socialItem {
	&::after {
		display: none;
	}
}

.copyright {
	font-size: 0.8rem;
	@media screen and (min-width: 320px) {
		font-size: calc(0.8rem + 4 * (100vw - 320px) / 1180);
	}
	@media screen and (min-width: 1180px) {
		font-size: 11px;
	}
}
