.header {
	display: none;
}

.background {
	position: fixed;
	height: 100vh;
	width: 100vw;
	top: -50px;
	z-index: 1;
	@media (min-width: 767px) {
		top: 0;
	}
}

.description {
	align-items: flex-end;
	display: flex;
	flex: 1;
	height: 100%;
	z-index: 2;
	font-size: 0.8rem;
	width: 100%;

	@media (min-width: 767px) {
		width: 40%;
	}

	@media screen and (min-width: 320px) {
		font-size: calc(0.8rem + 4 * (100vw - 320px) / 1180);
	}

	@media screen and (min-width: 1180px) {
		font-size: 11px;
	}
}
