.container {
	flex-shrink: 0;
	pointer-events: auto;
	transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
	padding: 35px 0px 20px 0;
	z-index: 10;
}

.content {
	display: flex;
}

.nav {
	margin-left: auto;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
}

.item {
	display: flex;

	&:last-child {
		& .link {
			margin-right: 0;
		}
	}
}

.link {
	display: block;
	cursor: pointer;
	margin-right: 10px;

	font-size: 0.8rem;

	@media screen and (min-width: 320px) {
		font-size: calc(0.8rem + 4 * (100vw - 320px) / 1180);
	}

	@media screen and (min-width: 1180px) {
		font-size: 11px;
	}
}
