.title {
	color: white;
	font-family: 'DINCondensed', sans-serif;
	font-weight: normal;
	line-height: 1;
	text-decoration: none;
	transition: all 0.6s;
	white-space: nowrap;
	letter-spacing: 0.1em;
	pointer-events: auto;
}
